<template>
  <div>
    <home-top></home-top>
    <div class="mycontainer-main campaign-content">
      <div class="caption">
        <span>
          {{
            (item.display_start ? convertUTCToClientTime(item.display_start).format('YYYY/MM/DD') : '') +
            ' - ' +
            (item.display_end ? convertUTCToClientTime(item.display_end).format('YYYY/MM/DD') : '')
          }}
        </span>
      </div>

      <div v-show="showLocalisation">
        <div id="modal-campaign">
          <home-localization-map :isReInitHeader="1"></home-localization-map>
        </div>
      </div>

      <div class="content-box" v-html="item.content"></div>
    </div>

    <home-footer></home-footer>
  </div>
</template>

<script>
import HomeLocalizationMap from '@/components/home/HomeLocalizationMap'

export default {
  components: {
    HomeLocalizationMap
  },
  mounted () {
    this.code = this.$route.params.code
    this.name = this.$route.params.name
    this.initData()
    this.eventTrackingScreenLoad(this, {screen: {'name': 'campaign.detail', 'type': 'campaign'}})

    const body = document.getElementsByTagName('body')[0]
    body.id = 'page-topic-detail'
  },
  beforeCreate: function () {
  },
  beforeDestroy: function () {
    const body = document.getElementsByTagName('body')[0]
    body.id = ''
  },
  data () {
    return {
      code: '',
      name: '',
      item: {},
      showLocalisation: 0
    }
  },
  methods: {
    /**
     * Init data
     * */
    initData () {
      let vm = this
      let data = {'url': window.location.pathname}
      vm.axios.apiTopic.getTopicDetail(data, function (response) {
        vm.item = response.data.data.item
        if (vm.item.content) {
          vm.item.content = vm.lodash.unescape(vm.item.content)
        }

        // vm.showLocalisation = vm.item.localisation;
        vm.showLocalisation = vm.item.include_localisation === 1
      })
    },
    showModal () {
      let vm = this
      if (vm.showLocalisation === 1) {
        this.$modal.show(HomeLocalizationMap, {
          showCloseButton: true
        }, {
          class: 'modal-campaign',
          transition: 'nice-modal-fade',
          overlayTransition: 'overlay-fade',
          clickToClose: true,
          name: 'modal-campaign'
        }, {
          'before-open': function (event) {
            document.body.classList.add('modal-open')
          },
          'before-close': function (event) {
            document.body.classList.remove('modal-open')
          }
        })
      }
    }
  }
}

</script>
<style scoped>
  .mycontainer-main {
    max-width: 1000px;
    margin: auto;
  }

</style>
<style>
  .campaign-content .image {
    width: auto !important;
  }
</style>
